import { useState } from "react"

import {
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "src/components/Settings/Setting/SettingContainer"
import { usePutDevice } from "src/data/devices/queries/deviceQueries"
import { TMaybeHome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchHome,
  useFetchHomes,
} from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Combobox } from "src/ui/Combobox/Combobox"

export function SettingHomeTransfer({
  deviceId,
  homeId,
}: {
  deviceId: string
  homeId: string
}) {
  const { t, langKeys } = useTranslate()
  const { org, orgId } = useOrganization()
  const [search, setSearch] = useState("")
  const putDevice = usePutDevice(orgId)
  const fetchHome = useFetchHome({
    orgId: org.id,
    homeId,
  })
  const [selectedHome, setSelectedHome] = useState<TMaybeHome>(
    fetchHome.data ?? null
  )
  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: { name: search },
  })

  async function handleSave(
    home: TMaybeHome
  ): TSettingContainerOnSaveReturnType {
    try {
      await putDevice.mutateAsync({
        id: deviceId,
        data: { home: home?.home_id },
      })
      setSelectedHome(home)

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  return (
    <SettingContainer
      title={t(langKeys.home)}
      description={t(langKeys.device_home_transfer_setting_body)}
      onSave={() => handleSave(selectedHome)}
      displayValue={String(selectedHome?.name)}
      onClose={() => {
        setSelectedHome(fetchHome.data ?? null)
        setSearch("")
      }}
      InputComponent={() => (
        <Combobox
          options={
            fetchHomes.data?.homes.map((home) => ({
              value: home.home_id,
              label: home.name || home.home_id,
            })) ?? []
          }
          selectedValue={selectedHome?.home_id ?? ""}
          initialInput={selectedHome?.name}
          onSearch={setSearch}
          onChange={(value) => {
            const selected = fetchHomes.data?.homes.find(
              (home) => home.home_id === value
            )

            setSelectedHome(selected ?? null)
          }}
          label={t(langKeys.home_plural)}
        />
      )}
    />
  )
}
